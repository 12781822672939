import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/authentication/token-interceptor';
import { ErrorInterceptor } from './core/authentication/errror-interceptor';
import { provideToastr } from 'ngx-toastr';
import { AuthGuard } from './core/authentication/auth.guard';
import { Action, provideStore } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { AppState } from './shared/store/store';
import { userReducer } from './shared/store/reducers/auth.reducers';
import { settingReducer } from './shared/store/reducers/setting.reducer';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
const reducers: Record<keyof AppState, (state: any, action: Action) => any> = {
    user: userReducer,
    config: settingReducer,
};

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        AuthGuard,
        provideToastr(),
        provideAnimations(),
        provideRouter(routes),
        provideClientHydration(),
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        importProvidersFrom(HttpClientModule),
        provideStore(reducers),
    ],
};
