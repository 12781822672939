import { Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('./pages/authentication/sign-in/sign-in.component').then(
                (m) => m.SignInComponent
            ),
    },
    {
        path: 'sign-up',
        loadComponent: () =>
            import('./pages/authentication/sign-up/sign-up.component').then(
                (m) => m.SignUpComponent
            ),
    },
    {
        path: 'forgot-password',
        loadComponent: () =>
            import(
                './pages/authentication/forgot-password/forgot-password.component'
            ).then((m) => m.ForgotPasswordComponent),
    },
    {
        path: 'reset-password/:token',
        loadComponent: () =>
            import(
                './pages/authentication/reset-password/reset-password.component'
            ).then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'reset-password',
        loadComponent: () =>
            import(
                './pages/authentication/reset-password/reset-password.component'
            ).then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'lock-screen',
        loadComponent: () =>
            import(
                './pages/authentication/lock-screen/lock-screen.component'
            ).then((m) => m.LockScreenComponent),
    },
    {
        path: 'confirm-email',
        loadComponent: () =>
            import(
                './pages/authentication/confirm-email/confirm-email.component'
            ).then((m) => m.ConfirmEmailComponent),
    },
    {
        path: 'logout',
        loadComponent: () =>
            import('./pages/authentication/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },
    {
        path: 'user',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'User' },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/user/user.component').then(
                        (m) => m.UserComponent
                    ),
                data: { breadcrumb: 'View' },
            },
            {
                path: 'add',
                data: {
                    breadcrumb: 'Add',
                },
                loadComponent: () =>
                    import(
                        './pages/user/manage-user/manage-user.component'
                    ).then((m) => m.ManageUserComponent),
            },
            {
                path: 'edit/:id',
                loadComponent: () =>
                    import(
                        './pages/user/manage-user/manage-user.component'
                    ).then((m) => m.ManageUserComponent),
                data: {
                    breadcrumb: 'Edit',
                },
            },
        ],
    },
    {
        path: 'role',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Role' },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/role/role.component').then(
                        (m) => m.RoleComponent
                    ),
                data: { breadcrumb: 'List' },
            },
            {
                path: 'view/:id',
                loadComponent: () =>
                    import(
                        './pages/role/manage-role/manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
                data: { breadcrumb: 'View' },
            },
            {
                path: 'edit/:id',
                loadComponent: () =>
                    import(
                        './pages/role/manage-role/manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
                data: { breadcrumb: 'Edit' },
            },
            {
                path: 'add',
                loadComponent: () =>
                    import(
                        './pages/role/manage-role/manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
                data: { breadcrumb: 'Add' },
            },
            {
                path: 'permission/:id',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Permission' },
                loadComponent: () =>
                    import(
                        './pages/role/role-permission/role-permission.component'
                    ).then((m) => m.RolePermissionComponent),
            },
        ],
    },
    {
        path: 'location',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Location' },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'View' },
                loadComponent: () =>
                    import('./pages/location/location.component').then(
                        (m) => m.LocationComponent
                    ),
            },
            {
                path: 'add',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Add' },
                loadComponent: () =>
                    import(
                        './pages/location/manage-location/manage-location.component'
                    ).then((m) => m.ManageLocationComponent),
            },
            {
                path: 'edit/:id',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Edit' },
                loadComponent: () =>
                    import(
                        './pages/location/manage-location/manage-location.component'
                    ).then((m) => m.ManageLocationComponent),
            },
        ],
    },
    {
        path: 'product',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Product' },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'List' },
                loadComponent: () =>
                    import('./pages/product/product.component').then(
                        (m) => m.ProductComponent
                    ),
            },
            {
                path: ':id',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'View' },
                loadComponent: () =>
                    import('./pages/product/detail/detail.component').then(
                        (m) => m.DetailComponent
                    ),
            },
        ],
    },
    {
        path: 'cart',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Cart' },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'List' },
                loadComponent: () =>
                    import('./pages/product/checkout/checkout.component').then(
                        (m) => m.CheckoutComponent
                    ),
            },
        ],
    },
    {
        path: 'return',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'return' },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'List' },
                loadComponent: () =>
                    import(
                        './pages/return/return-list/return-list.component'
                    ).then((m) => m.ReturnListComponent),
            },
        ],
    },
    {
        path: 'allocation',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Allocation' },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/product/product-assignee-view/product-assignee-view.component'
                    ).then((m) => m.ProductAssigneeViewComponent),
                data: {
                    breadcrumb: 'View Allocation',
                },
            },
            {
                path: 'create',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/product/product-assignee/product-assignee.component'
                    ).then((m) => m.ProductAssigneeComponent),
                data: {
                    breadcrumb: 'Save',
                },
            },
        ],
    },
    {
        path: 'order',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Order' },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/order/order-list/order-list.component'
                    ).then((m) => m.OrderListComponent),
                data: {
                    breadcrumb: 'View Order',
                },
            },
            {
                path: 'view/:id',

                loadComponent: () =>
                    import(
                        './pages/order/order-view/order-view.component'
                    ).then((m) => m.OrderViewComponent),
                data: {
                    breadcrumb: 'View Order',
                },
                children: [
                    {
                        path: '',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-details/order-details.component'
                            ).then((m) => m.OrderDetailsComponent),
                        data: {
                            breadcrumb: 'Order Details',
                        },
                    },
                    {
                        path: 'log',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-logs/order-logs.component'
                            ).then((m) => m.OrderLogsComponent),
                        data: {
                            breadcrumb: 'Order Logs',
                        },
                    },
                    {
                        path: 'invoices',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-invoices/order-invoices.component'
                            ).then((m) => m.OrderInvoicesComponent),
                        data: {
                            breadcrumb: 'Order Invoices',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'report',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Report',
        },
        children: [
            {
                path: 'purchase-history',
                canActivate: [AuthGuard],
                loadComponent: () =>
                    import(
                        './pages/report/purchase-history/purchase-history.component'
                    ).then((m) => m.PurchaseHistoryComponent),
                data: {
                    breadcrumb: 'Purchase History',
                },
            },
        ],
    },
    {
        path: 'invoices',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import(
                './pages/invoice/invoice-list/invoice.component'
            ).then((m) => m.InvoiceComponent),
            data: {
                breadcrumb: 'Invoice List',
            },
    },
    {
        path: 'invoice',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/invoice/invoice-list/invoice.component'
                    ).then((m) => m.InvoiceComponent),
                    data: {
                        breadcrumb: 'Invoice List',
                    },
            },
            {
                path: 'view/:id',
                loadComponent: () =>
                    import(
                        './pages/invoice/invoice-view/invoice-view.component'
                    ).then((m) => m.InvoiceViewComponent),
                    data: {
                        breadcrumb: 'Invoice',
                    },
            },
        ],
    },
    {
        path: 'my-profile',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./pages/my-profile/my-profile.component').then(
                (m) => m.MyProfileComponent
            ),
        data: { breadcrumb: 'My Profile' },
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./pages/dashboard/dashboard.component').then(
                (m) => m.DashboardComponent
            ),
    },
    {
        path: 'internal-error',
        loadComponent: () =>
            import(
                './core/layouts/internal-error/internal-error.component'
            ).then((m) => m.InternalErrorComponent),
    },

    {
        path: '**',
        loadComponent: () =>
            import('./core/layouts/not-found/not-found.component').then(
                (m) => m.NotFoundComponent
            ),
    },
];
