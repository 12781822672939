import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageManagerService } from './storage-manager.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../shared/store/store';
import { loginAction } from '../../shared/store/actions/auth.actions';
import { configAction } from '../../shared/store/actions/setting.reducer';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _router: Router, private store: Store<AppState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (
            StorageManagerService.getToken() &&
            StorageManagerService.getUser()
        ) {
            const secondsInDay = 24 * 60 * 60;
            const difference = Math.abs(
                Math.floor(Date.now() / 1000) -
                    StorageManagerService.getToken().expiredTime
            );

            if (difference > secondsInDay) {
                StorageManagerService.clearAll();
                this._router.navigate(['/', 'login']);
                return true;
            }

            this.store.dispatch(
                loginAction({
                    user: {
                        id: StorageManagerService.getUser().id,
                        emailAddress:
                            StorageManagerService.getUser().emailAddress,
                        firstName: StorageManagerService.getUser().firstName,
                        lastName: StorageManagerService.getUser().lastName,
                        status: StorageManagerService.getUser().status,
                        profileImage:
                            StorageManagerService.getUser().profileImage,
                    },
                })
            );
            if (StorageManagerService.getConfig()) {
                this.store.dispatch(
                    configAction({
                        config: {
                            country: StorageManagerService.getConfig().country,
                            currency:
                                StorageManagerService.getConfig().currency,
                            timezone:
                                StorageManagerService.getConfig().timezone,
                        },
                    })
                );
            }
            return true;
        } else {
            StorageManagerService.clearAll();
            this._router.navigate(['/', 'login']);
            return true;
        }
    }
}
