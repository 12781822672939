import { createAction, props } from '@ngrx/store';
import { Country, Currency, Timezone } from '../../services/api/config.service';

export const configAction = createAction(
    '[setting] config',
    props<{ config: Config }>()
);

export interface Config {
    country: Country[];
    currency: Currency[];
    timezone: Timezone[];
}
