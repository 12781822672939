<div
    class="sidebar-area bg-white active"
    [ngClass]="{ active: isSidebarToggled }"
    [class.component-dark-theme]="false"
    [class.dark-sidebar]="true"
    [class.right-sidebar]="false"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img
                src="assets/images/logo-transparant.svg"
                alt="logo-icon"
                class="h-35"
            />
            <span class="fw-semibold">Platform 1</span>
        </a>
    </div>
    <div
        (click)="toggle()"
        class="burger-menu"
        [ngClass]="{ active: isSidebarToggled }"
    >
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <br />
                    <div>
                        <a
                            class="sidebar-menu-link p0 d-flex align-items-center profilePicture cursor-pointer"
                            [matMenuTriggerFor]="profileMenu"
                            #menuTrigger="matMenuTrigger"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <!-- <i class="material-symbols-outlined"> account_box </i> -->
                            <div>
                                <img
                                    src="{{ this.profilePicture }}"
                                    class="rounded-circle"
                                />
                            </div>
                            <span
                                class="title ps1 d-flex align-items-center position-relative"
                            >
                                {{ userName }}

                                <i
                                    class="material-symbols-outlined expand-arrow"
                                >
                                    {{
                                        menuTrigger.menuOpen
                                            ? "expand_less"
                                            : "expand_more"
                                    }}
                                </i>
                                <mat-menu
                                    class="position-absolute end-0 top-0"
                                    #profileMenu="matMenu"
                                >
                                    <button
                                        mat-menu-item
                                        routerLinkActive="active"
                                        routerLink="/my-profile"
                                    >
                                        My Profile
                                    </button>
                                </mat-menu>
                            </span>
                        </a>
                    </div>
                    <a
                        routerLink="/"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined">
                            team_dashboard
                        </i>
                        <span class="title"> Dashboard </span>
                    </a>
                    <a
                        *ngIf="helperservice.isSuperAdmin()"
                        routerLink="/user"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined"> people </i>
                        <span class="title"> Users </span>
                    </a>
                    <a
                        *ngIf="helperservice.isSuperAdmin()"
                        routerLink="/role"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined">
                            manage_accounts
                        </i>
                        <span class="title"> Roles </span>
                    </a>
                    <a
                        *ngIf="helperservice.isSuperAdmin()"
                        routerLink="/location"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined"> location_on </i>
                        <span class="title"> Locations </span>
                    </a>
                    <a
                        *ngIf="helperservice.isSuperAdmin()"
                        routerLink="/allocation"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined"> assignment </i>
                        <span class="title">Allocations </span>
                    </a>
                    <a
                        routerLink="/product"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            exact: false
                        }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined"> inventory_2 </i>
                        <span class="title"> Products </span>
                    </a>
                    <a
                        routerLink="/order"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            exact: false
                        }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined"> order_approve </i>
                        <span class="title"> Orders </span>
                    </a>
                    <mat-expansion-panel
                        class=""
                        (opened)="panelOpenState = true"
                        *ngIf="helperservice.isSuperAdmin()"
                        (closed)="panelOpenState = true"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="material-symbols-outlined">
                                    description
                                </i>
                                <span class="title"> Reports </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-accordion>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item parent-side mt-10">
                                    <a
                                        routerLink="/report/purchase-history"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: false
                                        }"
                                        class="sidebar-menu-link mb-0"
                                    >
                                        <i class="material-symbols-outlined">
                                            overview
                                        </i>
                                        <span class="title">
                                            Purchase History
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </mat-accordion>
                    </mat-expansion-panel>
                    <!-- <mat-expansion-panel
                        class=""
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = true"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="material-symbols-outlined">
                                    inventory_2
                                </i>
                                <span class="title"> Product </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-accordion>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item parent-side mt-10">
                                    <a
                                        routerLink="/product"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact:false
                                        }"
                                        class="sidebar-menu-link"
                                    >
                                        <i class="material-symbols-outlined">
                                            inventory_2
                                        </i>
                                        <span class="title"> Product </span>
                                    </a>
                                </li>
                            </ul>
                        </mat-accordion>
                    </mat-expansion-panel> -->
                    <a
                        routerLink="/return"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sidebar-menu-link"
                    >
                        <i class="material-symbols-outlined">
                            assignment_return
                        </i>
                        <span class="title"> Returns </span>
                    </a>
                    <a
                        routerLink="/logout"
                        class="sidebar-menu-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                    >
                        <i class="material-symbols-outlined"> logout </i>
                        <span class="title">Logout</span>
                    </a>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
