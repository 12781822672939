import { Component, HostListener, ElementRef } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import {
    NavigationCancel,
    NavigationEnd,
    Router,
    RouterLink,
    RouterLinkActive,
    RouterModule,
} from '@angular/router';
import { ToggleService } from './toggle.service';
import { CommonModule, NgClass } from '@angular/common';
import { CustomizerSettingsService } from '../../../shared/components/customizer-settings/customizer-settings.service';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { filter } from 'rxjs';
import { HelperService } from '../../../shared/services/helper.service';
import { StorageManagerService } from '../../authentication/storage-manager.service';
import { environment } from '../../../../environments/environment';
import { MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        NgScrollbarModule,
        MatExpansionModule,
        RouterLinkActive,
        RouterModule,
        RouterLink,
        NgClass,
        HeaderComponent,
        FooterComponent,
        LoaderComponent,
        CommonModule,
        MatMenuModule,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    isSidebarToggled = false;
    isToggled = false;
    isMobile: boolean = false;
    title = 'Platform 1';
    routerSubscription: any;
    location: any;
    mediaUrl: string = environment.image_url;
    profilePicture: string = this.mediaUrl + 'products/no_image.jpg';
    userName: string = '';
    constructor(
        public router: Router,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private eRef: ElementRef,
        public helperservice: HelperService
    ) {
        this.toggleService.isSidebarToggled$.subscribe((isSidebarToggled) => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });

        if (
            StorageManagerService?.getUser()?.profileImage != '' &&
            StorageManagerService?.getUser()?.profileImage != null
        ) {
            this.profilePicture =
                this.mediaUrl + StorageManagerService.getUser().profileImage;
        }
        if (
            StorageManagerService?.getUser() != '' &&
            StorageManagerService?.getUser() != null
        ) {
            this.userName =
                StorageManagerService.getUser().firstName +
                ' ' +
                StorageManagerService.getUser().lastName;
        }
    }
    ngOnInit() {
        if (window.innerWidth < 1200) {
            this.toggleService.toggle();
        }
        this.recallJsFuntions();
    }
    // recallJsFuntions
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    // Card Border Radius
    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    // Burger Menu Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // Mat Expansion
    panelOpenState = false;

    // Dark Mode
    toggleTheme() {
        this.themeService.toggleTheme();
    }

    // Sidebar Dark
    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    // Right Sidebar
    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    // Hide Sidebar
    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    // Header Dark Mode
    toggleHeaderTheme() {
        this.themeService.toggleHeaderTheme();
    }

    // Card Border
    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    // RTL Mode
    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        if (window.innerWidth < 1200) {
            this.toggleService.toggle();
        }
    }

    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {
        if (
            window.innerWidth < 1200 &&
            !this.eRef.nativeElement.contains(event.target)
        ) {
            this.toggleService.isSidebarToggled.next(true);
        }
    }
}
